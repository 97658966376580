import React, { useState } from "react";

function HubblePage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="bg-white shadow-md " style={{ height: "100px" }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-full">
            <div className="flex items-center justify-center">
              <div className="flex-shrink-0 flex items-center  ">
                <img
                  className="h-20 w-25 mt-4 mb-4"
                  src="https://static.wixstatic.com/media/8cf635_8287b52e33fb4d7c8530c27544f613db~mv2.png/v1/fill/w_55,h_55,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image%20(1).png"
                  alt="Hubble Logo"
                />
                <img
                  className="h-16 w-auto ml-2  mt-4 mb-4"
                  src="https://static.wixstatic.com/media/8cf635_08b7c235e16d4a2bba6413a35574ef57~mv2.png/v1/fill/w_135,h_40,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/image.png"
                  alt="Hubble Logo Text"
                />
              </div>
            </div>
          </div>
        </div>
      </nav>


      <div
        className="relative bg-cover bg-center h-100"
        style={{ backgroundImage: "url(/assests/unnamed.jpg)" }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 max-w-7xl mx-auto py-32 px-4 sm:py-48 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-extrabold text-white sm:text-5xl lg:text-6xl">
            Welcome to Hubble
          </h1>
          <p className="mt-6 text-2xl text-gray-300 max-w-3xl mx-auto">
            connecting artists one gig at a time
          </p>
          <p className="mt-2 text-blue-500 font-poppins text-2xl">
            <a
              href="https://apps.apple.com/in/app/id6475806494"
              className="underline text-blue-400"
            >
              Click here to download
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default HubblePage;
