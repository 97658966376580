import React,{useEffect} from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,

} from "react-router-dom";
import "./App.css";
import Dashboard from "./Pages/Dashboard";
import Bookings from "./Pages/Bookings";
import Artist from "./Pages/Artist";
import Report from "./Pages/Report";
import Users from "./Pages/Users";
import ReviewDataTable from "./Pages/Review/ReviewDatatable";
import { AuthProvider, useAuth } from "./Components/Auth/AuthContext";
import LoginForm from "./Components/Login/LoginForm";
import Review from "./Pages/Review/Review";
import HubblePage from "./Pages/HubblePage";

const PrivateRoute = ({ children }) => {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? children : <Navigate to="/login" />;
};

const RedirectToAppleStore = () => {
  const navigate = useNavigate();
  const appleStoreUrl = "https://apps.apple.com/in/app/id6475806494";

  useEffect(() => {
    window.location.href = appleStoreUrl;
  }, [navigate]);

  return null; 
};
function App() {
  return (
    <Router>
      <AuthProvider>
        <div>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route path="/ul/*" element={<RedirectToAppleStore />} />

            <Route
              path="/"
              element={
                <PrivateRoute>
                  <Dashboard />
                </PrivateRoute>
              }
            />
            <Route
              path="/bookings"
              element={
                <PrivateRoute>
                  <Bookings />
                </PrivateRoute>
              }
            />
            <Route
              path="/artists"
              element={
                <PrivateRoute>
                  <Artist />
                </PrivateRoute>
              }
            />
            <Route
              path="/report"
              element={
                <PrivateRoute>
                  <Report />
                </PrivateRoute>
              }
            />
            <Route
              path="/users"
              element={
                <PrivateRoute>
                  <Users />
                </PrivateRoute>
              }
            />
            <Route
              path="/review"
              element={
                <PrivateRoute>
                  <ReviewDataTable />
                </PrivateRoute>
              }
            />
            <Route
              path="/reviews/:artistId"
              element={
                <PrivateRoute>
                  <Review />
                </PrivateRoute>
              }
            />
          </Routes>
        </div>
      </AuthProvider>
    </Router>
  );
}

export default App;