import axios from "axios";
import moment from "moment";

export const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

Api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      return Promise.reject({ ...error, customError: "403" });
    }
    return Promise.reject(error);
  }
);

export const searchArtist = async (query) => {
  try {
    const response = await Api.get("/admin/search-artist", {
      params: { artistName: query },
    });
    return response.data.body;
  } catch (error) {
    console.error("Error fetching Spotify artists:", error);
    return [];
  }
};

export const fetchBookings = async () => {
  try {
    const response = await Api.get("/admin/bookings");
    if (!response.data.success) {
      throw new Error(
        `Error fetching reports: ${response.status} ${response.statusText}`
      );
    }

    const bookings = response.data.body.map((bookings, index) => ({
      id: index + 1,
      userId: bookings._id,
      bookingNumber: bookings.bookingNumber,
      totalAmount: bookings.totalAmount,
      taxAmount: bookings.taxAmount,
      serviceFee: bookings.serviceFee,
      hubbleFee: bookings.hubbleFee,
      userName: bookings.userName,
      artistName: bookings.artistName,
      bookingStatus: bookings.bookingStatus,
    }));

    return bookings;
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw error;
  }
};

export const fetchArtists = async () => {
  try {
    const response = await Api.get("/admin/artists");
    const artists = response.data.body.map((artist, index) => ({
      index: index + 1,
      userId: artist.userId,
      email: artist.email,
      userName: artist.userName,
      userType: artist.userType,
      profilePictureKey: artist.profilePictureKey,
      numberOfBooking: artist.numberOfBooking,
    }));
    return artists;
  } catch (error) {
    console.error("Error fetching artists:", error);
    throw error;
  }
};

export const fetchReport = async () => {
  try {
    const response = await Api.get("/admin/reported-artists");
    if (!response.data.success) {
      throw new Error(
        `Error fetching reports: ${response.status} ${response.statusText}`
      );
    }

    const reports = response.data.body.map((report, index) => ({
      id: index + 1,
      userId: report._id,
      reportBy: report.reportBy,
      reportTo: report.reportTo,
      reportDescription: report.reportDescription,
      reportType: report.reportType,
    }));

    return reports;
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw error;
  }
};

export const fetchUsers = async () => {
  try {
    const response = await Api.get("/admin/users");
    if (!response.data.success) {
      throw new Error(
        `Error fetching reports: ${response.status} ${response.statusText}`
      );
    }

    const users = response.data.body.map((users, index) => ({
      id: index + 1,
      userId: users._id,
      email: users.email,
      userName: users.userName,
      userType: users.userType,
      profilePictureKey: users.profilePictureKey,
      primaryLocation: users.primaryLocation.address.city
        ? users.primaryLocation.address.city
        : users.primaryLocation.address.countryOrRegion,
      numberOfBooking: users.numberOfBooking,
    }));

    return users;
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw error;
  }
};

export const LoginUser = async (payload) => {
  try {
    const response = await Api.post("/admin/login", payload);
    return response.data || [];
  } catch (error) {
    throw error.response ? error.response.data : error.message;
  }
};

export const fetchReviewArtist = async (sortby = "", sortOrder = "") => {
  try {
    const response = await Api.get(
      `/admin/reviewdb/artists?sortBy=${sortby}&sortOrder=${sortOrder}`
    );
    if (!response.data.success) {
      throw new Error(
        `Error fetching Reviewdb Artist: ${response.status} ${response.statusText}`
      );
    }

    const reviews = response.data.body.map((review, index) => ({
      id: index + 1,
      artistId: review.artistId,
      photo: review.profileImage,
      artistName: review.artistName,
      location: review.location || "Unknown location",
      bio: review.bio || "No bio available",
      genre: Array.isArray(review.genres)
        ? review.genres.join(", ")
        : "Unknown",
      platforms: review.Platforms || {},
      followers: review.followers,
      popularity: review.popularity,
      age: review.age || "Unknown",
    }));

    return reviews;
  } catch (error) {
    console.error("Error fetching review artist:", error);
    throw error;
  }
};

export const saveArtistData = async (spotifyId, formData) => {
  try {
    const response = await Api.post(
      `/admin/reviewdb/artist/${spotifyId}`,
      formData
    );
    if (!response.data.body.success) {
      alert(`Error saving artist data: ${response.data.body.message}`);
    }
    return response.data;
  } catch (error) {
    console.error("Error saving artist data:", error);
    throw error;
  }
};

export const saveCustomArtistData = async (formData) => {
  try {
    const response = await Api.post(`/admin/reviewdb/custom-artist`, formData);
    if (!response.data.body.success) {
      alert(`Error saving artist data: ${response.data.body.message}`);
    }

    return response.data;
  } catch (error) {
    console.error("Error saving artist data:", error);
    console.error("Error saving custom artist data:", error.response || error);
    throw error;
  }
};

export const fetchRecentBookings = async () => {
  try {
    const response = await Api.get("/admin/bookings");
    if (!response.data.success) {
      throw new Error(
        `Error fetching reports: ${response.status} ${response.statusText}`
      );
    }

    const bookings = response.data.body.slice(0, 6).map((booking, index) => ({
      id: index + 1,
      userId: booking._id,
      bookingNumber: booking.bookingNumber,
      totalAmount: booking.totalAmount,
      bookingStartTime: moment(booking.bookingStartTime).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
    }));

    return bookings;
  } catch (error) {
    console.error("Error fetching reports:", error);
    throw error;
  }
};

export const fetchDashboardData = async () => {
  try {
    const response = await Api.get("/admin/dashboard");
    if (!response.data.success) {
      throw new Error(
        `Error fetching dashboard data: ${response.status} ${response.statusText}`
      );
    }
    const { userCount, artistCount, bookingCount } = response.data.body;
    const dashboardData = {
      userCount: userCount || 0,
      artistCount: artistCount || 0,
      bookingCount: bookingCount || 0,
    };

    return dashboardData;
  } catch (error) {
    console.error("Error fetching dashboard data:", error);
    throw error;
  }
};

export const getSignedUrl = async (fileName, fileType) => {
  try {
    const response = await Api.put(`/media/admin/${fileName}`, {
      contentType: fileType,
    });
    if (response.status !== 200 || !response.data.success) {
      throw new Error("Failed to fetch pre-signed URL");
    }
    return response.data.body;
  } catch (error) {
    console.error("Error fetching signed Url:", error);
    throw error;
  }
};

export const deleteArtist = async (artistId) => {
  try {
    const response = await Api.delete(`admin/reviewdb/artist/${artistId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting artist:", error);
    throw error;
  }
};

export const updateArtist = async (artistId, formData) => {
  try {
    const response = await Api.patch(
      `admin/reviewdb/artist/${artistId}`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting artist:", error);
    throw error;
  }
};

export const getSingleArtist = async (artistId) => {
  try {
    const response = await Api.get(`admin/reviewdb/artist/${artistId}`);
    return response.data.body;
  } catch (error) {
    console.error("Error deleting artist:", error);
    throw error;
  }
};

export const getArtistReview = async (artistId) => {
  try {
    const response = await Api.get(`admin/reviewdb/all-reviews/${artistId}`);
    return response.data.body;
  } catch (error) {
    console.error("Error fetching  reviews:", error);
    throw error;
  }
};

export const deleteArtistReview = async (reviewId) => {
  try {
    const response = await Api.delete(`admin/reviewdb/reviews/${reviewId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting artist:", error);
    throw error;
  }
};
